







































































































































import User from "@/utility/user";
import Vue from "vue";
import { Component, Prop, PropSync, Watch } from "vue-property-decorator";
import DateFormatterMixin from "@/mixins/dateFormatterMixin";
import SolanaWallet from "@/utility/nft/SolanaWallet";
import NFT, { convertNFTCollectionToMap } from "@/utility/nft/NFT";
import camelcaseKeys from "camelcase-keys";

@Component({
  mixins: [DateFormatterMixin]
})
export default class ChangeUserSubscriptionPlanDialog extends Vue {
  // 🚩 Flag which determines whether the dialog should be shown
  @PropSync("showDialog", { type: Boolean })
  public show!: boolean;

  // 📦 The user which should have their subscription plan changed
  @Prop()
  public user!: User;

  // 🚩 Flag for whether the dialog is expanded to a fullscreen
  private expanded: boolean = false;

  // 📦 The user's NFT wallet address
  private nftWallet: SolanaWallet | null = null;

  // 📦 The user's NFT collection
  private nftCollection: NFT[] = [];

  // 📦 The user's active NFT skins
  private activeNFTSkins: any = {
    astral: null,
    clipper: null,
    endeavour: null,
    horizon: null,
    performer: null
  };

  // 🚩 Flag for whether the NFTs are loading for the user
  private isLoading: boolean = false;

  // 📦 The user's NFT collection divided by bot type
  private get nftCollectionByBotType(): any {
    return this.nftCollection.length > 0
      ? convertNFTCollectionToMap(this.nftCollection)
      : {};
  }

  @Watch("user")
  private onShowDialogChanged(val: boolean, oldVal: boolean) {
    if (this.show) {
      this.fetchUserNFTCollectionAndBoundedWallet();
    }
  }

  /**
   * 🚀 Fetches the data available for the current user's NFT collection and related Solana wallet
   */
  public async fetchUserNFTCollectionAndBoundedWallet() {
    try {
      this.isLoading = true;
      let rawResponse = await Vue.prototype.$api.post(
        "/api/proxy",
        "/api/v1/admin/nftusers",
        {
          username: this.user.email
        }
      );
      let formattedResponse = camelcaseKeys(rawResponse.data, { deep: true });

      this.nftWallet = new SolanaWallet(
        formattedResponse.walletType,
        formattedResponse.ownerAddress
      );

      this.nftCollection =
        Object.keys(formattedResponse.nftTokenBots).length > 0
          ? formattedResponse.nftTokenBots
          : [];

      this.activeNFTSkins = {
        ...this.activeNFTSkins,
        ...formattedResponse.nftSelectBotImages
      };
    } catch (error) {
      this.nftWallet = null;
      this.nftCollection = [];
      this.activeNFTSkins = {
        astral: null,
        clipper: null,
        endeavour: null,
        horizon: null,
        performer: null
      };
    } finally {
      this.isLoading = false;
    }
  }
}
