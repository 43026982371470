const _ =  require("lodash");

export enum NFTRarity {
  Legendary = "legendary",
  Epic = "epic",
  Rare = "rare",
  Uncommon = "uncommon",
  Common = "common",
}

export default class NFT {
  constructor(
    public numberInCollection: number,
    public imageUrl: string,
    public skin: string,
    public botType: string,
    public tokenAddress: string,
    public rarity: NFTRarity,
    public tier: any
  ) {}
}

export function convertNFTCollectionToMap(nfts: NFT[]) {
  let mapByBotType: any = {};

  nfts.forEach((nft: NFT) => {
    mapByBotType[nft.botType] = mapByBotType[nft.botType] || [];
    mapByBotType[nft.botType].push(nft);
  });

  Object.keys(mapByBotType).sort();

  let nftRarityOrder = Object.values(NFTRarity);

  (Object.values(mapByBotType) as Array<NFT[]>).forEach((nfts: NFT[]) =>
    nfts.sort(
      (a: NFT, b: NFT) =>
        nftRarityOrder.indexOf(a.tier.sub) - nftRarityOrder.indexOf(b.tier.sub)
    )
  );

  // return _.chain(mapByBotType)
  //   .toPairs()
  //   .sortBy(0)
  //   .fromPairs()
  //   .value();
}
